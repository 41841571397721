import { defineStore } from 'pinia'
import homeQuery from '../apollo/queries/pages/home.gql'

export const useHomeStore = defineStore('home', {
  state: () => ({
    loading: true,
    data: {}
  }),
  getters: {
    getHome: (state) => {
      return state.data.home ? state.data.home.data.attributes : null
    }
  },
  actions: {
    async requestHomeData() {
      if (Object.keys(this.data).length !== 0) return

      const { data } = await useAsyncQuery(homeQuery) // eslint-disable-line no-undef

      if (data) this.data = data
    }
  }
})
